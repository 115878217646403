import { formatUnits } from "@ethersproject/units";
import { Box } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/context";
import { useStakingContract } from "../hooks";

export default function Reward3() {
  const [earnThree, setearnThree] = useState(0);
  const { account, signer } = useContext(AppContext);
  const StakingContract = useStakingContract(signer);
  const rewarCalculate = () => {
    setInterval(async () => {
      const earningthree = await StakingContract?.calcRewardsHour(account, 3);
      setearnThree(formatUnits(earningthree));
    }, 1000);
  };
  useEffect(() => {
    if (account) {
      rewarCalculate();
    }
  }, [account]);

  return (
    <Box
      color="#00EBB4"
      fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
      fontWeight="700"
    >
      {parseInt(earnThree).toLocaleString()}
    </Box>
  );
}

import { Box, Container, Grid } from "@material-ui/core";
import React from "react";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import EmailIcon from "@material-ui/icons/Email";
const Footer = () => {
  return (
    <Box mt={8}>
      <Container>
        <Box pt={5} pb={5}>
          <Grid container>
            <Grid item lg={12} sm={12} align="center">
              <Box maxwidth="lg">
                <embed
                  src="logo.svg"
                  alt=""
                />
              </Box>
              <Box
                color="white"
                fontWeight={600}
                fontSize={{ xs: 20, sm: 20, md: 30, lg: 20 }}
                style={{
                  padding: "16px 0",
                }}
              >
                LifeCrypto is an innovative payment network and a new kind of
                money.
              </Box>
              <Box>
                <a
                  style={{
                    margin: "0 8px",
                  }}
                  href="https://twitter.com/LIFElabsHQ">
                  <TwitterIcon
                    fontSize="large"
                    style={{
                      color: "white",
                    }}
                  />
                </a>
                <a
                  style={{
                    margin: "0 8px",
                  }}
                  href="https://t.me/LIFE10101">
                  <TelegramIcon
                    fontSize="large"
                    style={{
                      color: "white",
                    }}
                  />
                </a>
                <a
                  style={{
                    margin: "0 8px",
                  }}
                  href="mailto:team@lifedefi.co">
                  <EmailIcon
                    fontSize="large"
                    style={{
                      color: "white",
                    }}
                  />
                </a>
              </Box>
              <Box
                color="white"
                fontWeight={400}
                fontSize={{ xs: 16, sm: 16, md: 24, lg: 16 }}
                style={{
                  padding: "16px 0",
                }}
              >
                {" "}
                ©{new Date().getFullYear()} Life Inc. All rights reserved.
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;

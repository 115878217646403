import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Box, Container, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(23),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function SimpleAccordion() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box mt={4}>
      <Container maxWidth="lg">
        <Box
          color="#4558D5"
          fontSize={{ sm: "5rem", xs: "3rem", md: "3rem" }}
          fontWeight="fontWeightBold"
          fontFamily="'Oswald', sans-serif"
          pb={3}
        >
          FAQ
        </Box>
        <div className={classes.root}>
          <Accordion
            style={{
              backgroundColor: "#350909",
              padding: "8px 16px",

              color: "white",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                How the platform works?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Typography
                  style={{
                    fontSize: matches ? "1.3rem" : "1rem",
                    fontWeight: "bold",
                  }}
                >
                  Approved Staking
                </Typography>
                <Typography
                  style={{
                    fontSize: matches ? "1.3rem" : "1rem",
                  }}
                >
                  Allows your wallet to interact with the smart contract. You
                  must press this button prior to the first time you stake.
                </Typography>
                <Typography
                  style={{
                    fontSize: matches ? "1.3rem" : "1rem",
                    fontWeight: "bold",
                    marginTop: "16px",
                  }}
                >
                  Invest
                </Typography>
                <Typography
                  style={{
                    fontSize: matches ? "1.3rem" : "1rem",
                  }}
                >
                  Pressing this button will take you to a popup screen where you
                  can input the amount you wish to stake and confirm your stake.
                </Typography>
                <Typography
                  style={{
                    fontSize: matches ? "1.3rem" : "1rem",
                    fontWeight: "bold",
                    marginTop: "16px",
                  }}
                >
                  Harvest
                </Typography>
                <Typography
                  style={{
                    fontSize: matches ? "1.3rem" : "1rem",
                  }}
                >
                  This will allow you to withdraw your available earned rewards
                  only.
                </Typography>
                <Typography
                  style={{
                    fontSize: matches ? "1.3rem" : "1rem",
                    fontWeight: "bold",
                    marginTop: "16px",
                  }}
                >
                  Withdraw
                </Typography>
                <Typography
                  style={{
                    fontSize: matches ? "1.3rem" : "1rem",
                  }}
                >
                  Withdrawing will allow you to withdraw your original staked
                  token amount and your earned rewards at the end of the
                  relevant staking period.
                </Typography>
                <Typography
                  style={{
                    fontSize: matches ? "1.3rem" : "1rem",
                    fontWeight: "bold",
                    marginTop: "16px",
                  }}
                >
                  Re Invest
                </Typography>
                <Typography
                  style={{
                    fontSize: matches ? "1.3rem" : "1rem",
                  }}
                >
                  This will allow you to re-invest your staking rewards
                  available or at the end of the lock in period to re-stake the
                  initial staked token amount.
                </Typography>
                <Typography
                  style={{
                    fontSize: matches ? "1.3rem" : "1rem",
                    fontWeight: "bold",
                    marginTop: "16px",
                  }}
                >
                  Staking
                </Typography>
                <Typography
                  style={{
                    fontSize: matches ? "1.3rem" : "1rem",
                  }}
                >
                  Minimum amount required for staking: 10,000
                  <br />
                  Maximum amount allowed for staking: no maximum
                  <br />
                  <br />
                  When deciding on an amount to stake we recommend you calculate
                  whether the amount you are staking is viable against the gas
                  fee amount.
                  <br />
                </Typography>
                <Typography
                  style={{
                    fontSize: matches ? "1.3rem" : "1rem",
                    fontWeight: "bold",
                    marginTop: "16px",
                  }}
                >
                  Available Terms & Rates
                </Typography>
                <Typography
                  style={{
                    fontSize: matches ? "1.3rem" : "1rem",
                  }}
                >
                  12 Months: 39%
                  <br />
                  6 Months: 35%
                  <br />
                  1 Month: 30%
                  <br />
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#350909",
              padding: "8px 16px",

              color: "white",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                Is there a LIFE wallet? Where can I access it?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {" "}
                <Typography
                  style={{
                    fontSize: matches ? "1.3rem" : "1rem",
                  }}
                >
                  <ul>
                    <li>
                      We are currently working on the development of the LIFE
                      Wallet. Until then, you will require a software or
                      hardware wallet which is compatible with ERC20 tokens,
                      such as MetaMask, Trust Wallet or Ledger. We are unable to
                      guarantee that other wallets may connect or function
                      correctly with the ERC20 smart contract.
                      <br />
                      <br />
                      You will require an amount of ETH to be available in your
                      wallet for gas fees. Every interaction with the smart
                      contract will incur a gas fee.
                    </li>
                  </ul>
                </Typography>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#350909",
              padding: "8px 16px",
              color: "white",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                What is staking?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                style={{
                  fontSize: matches ? "1.3rem" : "1rem",
                }}
              >
                <ul>
                  <li>
                    Staking is earning rewards for locking in your tokens for a
                    certain period.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#350909",
              color: "white",
              padding: "8px 16px",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                So how does the staking system work?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li
                    style={{
                      fontSize: matches ? "1.3rem" : "1rem",
                    }}
                  >
                    The LIFE Staking System version 2.0 works via a blockchain
                    smart contract on the Ethereum network which locks in the
                    tokens for the duration of the period selected.
                  </li>
                  <br />
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#350909",
              color: "white",
              padding: "8px 16px",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                Can I stake in multiple lock up periods?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li
                    style={{
                      fontSize: matches ? "1.3rem" : "1rem",
                    }}
                  >
                    Yes
                  </li>
                  <br />
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#350909",
              color: "white",
              padding: "8px 16px",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                Can I add to the tokens staked?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li
                    style={{
                      fontSize: matches ? "1.3rem" : "1rem",
                    }}
                  >
                    Yes, you can add additional tokens to any of the three
                    options, however this will reset the lock in period to its
                    full period.
                  </li>
                  <br />
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#350909",
              color: "white",
              padding: "8px 16px",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                If I want to stake any additional tokens – how do I do it?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li
                    style={{
                      fontSize: matches ? "1.3rem" : "1rem",
                    }}
                  >
                    You connect the wallet address that is linked to our LIFE
                    staking portal, select the term you wish to stake in, and
                    click ‘Invest’ and then enter the additional amount you
                    would like to stake. Note however that each time you add to
                    your staked token amount on any of the three staking
                    periods, the lock in period will reset to the full staking
                    period (please refer to the example in the previous answer).
                  </li>
                  <br />
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#350909",
              color: "white",
              padding: "8px 16px",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                If I want to allocate tokens for staking to two or more staking
                pools, does it mean that I have to pay for gas each time?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li
                    style={{
                      fontSize: matches ? "1.3rem" : "1rem",
                    }}
                  >
                    Yes, each interaction with the smart contract requires a gas
                    fee.
                  </li>
                  <br />
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#350909",
              color: "white",
              padding: "8px 16px",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                Can I withdraw my staking rewards prior to the end of the
                staking period?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li
                    style={{
                      fontSize: matches ? "1.3rem" : "1rem",
                    }}
                  >
                    Yes, staking rewards are calculated on a daily rate and
                    issued daily. To withdraw, you simply need to click
                    'Harvest', which will prompt your wallet to request
                    authorization of this transaction. After you confirm (& pay
                    a gas fee), the tokens will appear on your wallet and can be
                    transferred, sold or re-staked in a different staking
                    period.
                  </li>
                  <br />
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#350909",
              color: "white",
              padding: "8px 16px",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                Can I exit the staking period early? Is there a penalty for
                doing so?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li
                    style={{
                      fontSize: matches ? "1.3rem" : "1rem",
                    }}
                  >
                    You cannot exit the staking period prior to its end – the
                    smart contract does not allow for that. You can re-claim
                    your staked tokens only at the end of the staking period.
                    You can however harvest your staking rewards at any time.
                  </li>
                  <br />
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#350909",
              color: "white",
              padding: "8px 16px",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                If I want to re-stake my rewards instead of harvesting them, how
                do I go about it?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li
                    style={{
                      fontSize: matches ? "1.3rem" : "1rem",
                    }}
                  >
                    You simply click the 'Re-invest' button, authorize the
                    transaction on your wallet, and enjoy greater rewards.
                  </li>
                  <br />
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#350909",
              color: "white",
              padding: "8px 16px",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                What happens if I do not claim my staked token amount and earned
                rewards at the end of the staking period? Will it still keep
                earning rewards?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li
                    style={{
                      fontSize: matches ? "1.3rem" : "1rem",
                    }}
                  >
                    Should you not withdraw or re-stake your original staked
                    token amount and earned rewards at the end of the staking
                    period, those tokens will stop earning rewards and will
                    remain locked in the contract until you withdraw them.
                  </li>
                  <br />
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            style={{
              backgroundColor: "#350909",
              color: "white",
              padding: "8px 16px",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                At the end of the staking period can I re-stake only part of the
                initial staked token amount?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li
                    style={{
                      fontSize: matches ? "1.3rem" : "1rem",
                    }}
                  >
                    Yes, you can withdraw the entire staked token amount and
                    then re-stake only a partial amount of that initial staked
                    token amount, this will involve two transactions and
                    therefore two gas fees. You can however re-stake the full
                    amount and benefit from only one gas fee.
                  </li>
                  <br />
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#350909",
              color: "white",
              padding: "8px 16px",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                I do not see my staked tokens on my wallet! Help!
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li
                    style={{
                      fontSize: matches ? "1.3rem" : "1rem",
                    }}
                  >
                    Your staked tokens will not be visible in your software or
                    hardware wallet, and will only be visible on the interface
                    of our staking platform as they are locked into the smart
                    contract. You will need to connect your wallet to our
                    staking platform to view your staked amount(s) and earned
                    rewards
                  </li>
                  <br />
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#350909",
              color: "white",
              padding: "8px 16px",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                Why don’t I see my earned rewards on my wallet?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li
                    style={{
                      fontSize: matches ? "1.3rem" : "1rem",
                    }}
                  >
                    Your earned rewards are allocated to your wallet address via
                    the smart contract, and can only be seen provided you use
                    the same address you staked from to connect with the staking
                    platform.
                  </li>
                  <br />
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#350909",
              color: "white",
              padding: "8px 16px",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                Help! I have lost access to my original wallet? What happens
                next?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li
                    style={{
                      fontSize: matches ? "1.3rem" : "1rem",
                    }}
                  >
                    Unfortunately if you have lost access to your Trust or
                    Metamask wallet we will be unable to recover your tokens for
                    you and advise you direct your queries to either of the
                    wallet providers.
                  </li>
                  <br />
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#350909",
              color: "white",
              padding: "8px 16px",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                How often are the staking rewards paid out? Is it after the
                lock-in period elapses?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li
                    style={{
                      fontSize: matches ? "1.3rem" : "1rem",
                    }}
                  >
                    Staking rewards are calculated and available for withdrawal
                    daily.
                  </li>
                  <br />
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#350909",
              color: "white",
              padding: "8px 16px",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                How do I claim my staking rewards?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li
                    style={{
                      fontSize: matches ? "1.3rem" : "1rem",
                    }}
                  >
                    To claim your staking rewards, once you are connected to the
                    portal as mentioned above, you simply need to click on
                    ‘Harvest’, pay a small gas fee and the tokens will be sent
                    to your wallet.
                  </li>
                  <br />
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#350909",
              color: "white",
              padding: "8px 16px",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                What are gas fees and why do I have to pay them?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li
                    style={{
                      fontSize: matches ? "1.3rem" : "1rem",
                    }}
                  >
                    Gas fees are payments made by users to compensate for the
                    computing energy required to process and validate
                    transactions on the Ethereum blockchain. They are outside of
                    our control.
                  </li>
                  <br />
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#350909",
              color: "white",
              padding: "8px 16px",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                Who determines the gas fees?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li
                    style={{
                      fontSize: matches ? "1.3rem" : "1rem",
                    }}
                  >
                    The exact price of the gas is determined by supply and
                    demand between the network's miners, who can decline to
                    process a transaction if the gas price does not meet their
                    threshold, and users of the network who seek processing
                    power.
                  </li>
                  <br />
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#350909",
              color: "white",
              padding: "8px 16px",
              transition: "all 1s ease-in-out",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{
                    color: "white",
                  }}
                />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography
                className={classes.heading}
                style={{
                  fontSize: matches ? "1.5rem" : "1.5rem",
                }}
              >
                Is there anything not covered here?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <ul>
                  <li
                    style={{
                      fontSize: matches ? "1.3rem" : "1rem",
                    }}
                  >
                    Feel free to reach out to us at{" "}
                    <a
                      href="support@lifecrypto.life"
                      target="_blank"
                      style={{
                        color: "#7499ea",
                      }}
                    >
                      {" "}
                      support@lifecrypto.life
                    </a>
                    , on our telegram group{" "}
                    <a
                      href="https://t.me/LIFE10101"
                      target="_blank"
                      style={{
                        color: "#7499ea",
                      }}
                    >
                      {" "}
                      https://t.me/LIFE10101
                    </a>{" "}
                    or on twitter{" "}
                    <a
                      href="https://twitter.com/lifelabshq"
                      target="_blank"
                      style={{
                        color: "#7499ea",
                      }}
                    >
                      {" "}
                      https://twitter.com/lifelabshq
                    </a>
                  </li>
                  <br />
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </Container>
    </Box>
  );
}

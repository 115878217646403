import { Box, Button, Container, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SimpleDialogDemo from "../models/staking";
import { withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import TimerCountDown from "./Timer";
import { formatUnits } from "@ethersproject/units";
import moment from "moment";
import { ProgressBar } from "./progress";
import {
  useStakingContract,
  useStakingContract1,
  useTokenContract,
} from "../hooks";

import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { useCallback, useContext } from "react";

import Loading from "../loading";
import { AppContext } from "../context/context";
import Reward1 from "./Reward1";
import Reward2 from "./Reward2";
import Reward3 from "./Reward3";
import TotalReward from "./TotalReward";

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    color: "black",
    "&.MuiTableCell-head": {
      color: "black !important",
    },
  },
});

const Home = () => {
  const [open, setOpen] = React.useState(false);
  const [indx, setIndex] = React.useState(0);
  const [loader, setloading] = useState(false);
  const { connect, disconnect, account, signer } = useContext(AppContext);

  const matches = useMediaQuery({ query: "(max-width: 1224px)" });
  const [selectedLockups, setSelectedLockups] = React.useState(0);

  const [progress, setProgress] = useState(0);
  const [staked, setStaked] = useState(0);
  const [totalstaked, setTotalstaked] = useState(0);

  // const { deactivate } = useWeb3React();
  const StakingContract = useStakingContract(signer);
  const tokenContract = useTokenContract(signer);
  const StakingContract1 = useStakingContract1();
  const [earnone, setearnone] = useState(0);
  const [earnTwo, setearnTwo] = useState(0);
  const [earnThree, setearnThree] = useState(0);
  const [totalEarn, settotalEarn] = useState(0);
  const [_balanceone, set_balanceone] = useState(0);
  const [_balanceTwo, set_balanceTwo] = useState(0);
  const [_balanceThree, set_balanceThree] = useState(0);
  const [_totalBalance, set_totalBalance] = useState(0);
  const [_timeone, set_timeone] = useState(0);
  const [_timeTwo, set_timeTwo] = useState(0);
  const [_timeThree, set_timeThree] = useState(0);

  const classes = useStyles();
  const Stakingplane = (e) => {
    setOpen(true);
    setIndex(e);
  };

  const StakingWith = async (e) => {
    if (account) {
      try {
        setloading(true);
        const tx = await StakingContract.withdrawAll(e, account);
        await tx.wait();
        toast.success("Success! Transaction Confirmed.");
        setloading(false);
        // init();
        // init1();
        window.location.reload();
      } catch (err) {
        if (err?.data?.message) {
          toast.error(err?.data?.message);
        } else {
          toast.error(err?.message);
        }
        setloading(false);
      }
    }
  };

  const ExtendlockUp = async (e) => {
    if (account) {
      setloading(true);
      try {
        const tx = await StakingContract.extendLockup(e);
        await tx.wait();
        toast.success("Success! Transaction Confirmed.");
        setloading(false);
        // init();
        // init1();
        window.location.reload();
      } catch (err) {
        if (err?.data?.message) {
          toast.error(err?.data?.message);
        } else {
          toast.error(err?.message);
        }
        setloading(false);
      }
    }
  };

  const CompleteWithDraw = async (e) => {
    if (account) {
      setloading(true);

      try {
        const tx = await StakingContract.CompleteWithDraw(e, account);
        await tx.wait();
        toast.success("Success! Transaction Confirmed.");
        setloading(false);
        // init();
        // init1();
        window.location.reload();
      } catch (err) {
        if (err?.data?.message) {
          toast.error(err?.data?.message);
        } else {
          toast.error(err?.message);
        }
        setloading(false);
      }
    }
  };

  const initTwo = useCallback(async () => {
    (async () => {
      try {
        if (account) {
          let item = 0;
          for (let index = 1; index <= 3; index++) {
            const locs = await StakingContract.isLockupPeriodExpired(
              account,
              index
            );
            if (!locs) {
              item += 1;
            }
          }
          setSelectedLockups(item);
          // setInterval(async () => {

          const _balanceone = await StakingContract?.getCurrentBalance(
            1,
            account
          );
          const _balanceTwo = await StakingContract?.getCurrentBalance(
            2,
            account
          );
          const _balanceThree = await StakingContract?.getCurrentBalance(
            3,
            account
          );

          // ..._balance,
          set_balanceone(formatUnits(_balanceone, 18));
          set_balanceTwo(formatUnits(_balanceTwo, 18));
          set_balanceThree(formatUnits(_balanceThree, 18));
          set_totalBalance(
            +formatUnits(_balanceone, 18) +
              +formatUnits(_balanceTwo, 18) +
              +formatUnits(_balanceThree, 18)
          );
          const { UnStakeTime: UnStakeTime1 } = await StakingContract?.PlanI(
            account
          );
          const { UnStakeTime: UnStakeTime2 } = await StakingContract?.PlanII(
            account
          );
          const { UnStakeTime: UnStakeTime3 } = await StakingContract?.PlanIII(
            account
          );
          console.log(+UnStakeTime3, "UnStakeTime3");
          set_timeone(+UnStakeTime1);
          set_timeTwo(+UnStakeTime2);
          set_timeThree(+UnStakeTime3);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [account]);

  useEffect(() => {
    initTwo();
  }, [account]);

  const initt = async () => {
    try {
      if (account) {
        const maxstake = await StakingContract.maxStake();

        const totalstake = await StakingContract.currentStaked();

        // console.log(maxstake, totalstake, "===>");

        const progressValue = (+totalstake / +maxstake) * 100;
        setProgress(progressValue);
        setStaked(formatUnits(totalstake, 18));
        setTotalstaked(formatUnits(maxstake, 18));
      } else {
        const maxstake = await StakingContract1.methods.maxStake().call();
        const totalstake = await StakingContract1.methods
          .currentStaked()
          .call();
        const progressValue = (+totalstake / +maxstake) * 100;
        setProgress(progressValue);
        setStaked(formatUnits(totalstake, 18));
        setTotalstaked(formatUnits(maxstake, 18));
      }

      // if (StakingContract1) {

      // } else if (StakingContract && account) {

      // }
    } catch (err) {}
  };

  React.useEffect(() => {
    initt();
  }, [StakingContract1, StakingContract]);

  return (
    <>
      <Loading loading={loader} />
      <SimpleDialogDemo
        setOpen={setOpen}
        open={open}
        index={indx}
        initTwo={initTwo}
        initOne={initt}
      />
      <Box>
        {/* <AppBar
          style={{
            padding: "3px",
            background: "#020344",
          }}
        > */}
        <Box
          id="header_link"
          style={{
            padding: "3px",
            color: "#fff",
            position: "absolute",
            left: "50%",
            top: "40px",
            transform: "translateX(-50%)",
          }}
        >
          Go back to{" "}
          <a
            style={{
              color: "#00ebb4",
            }}
            href="https://www.lifedefi.co"
          >
            www.lifedefi.co
          </a>
          </Box>

          <Box
            id="header_button"
            style={{
              position: "absolute",
              left: "50%",
              top: "30px",
              transform: "translateX(250px)"
            }}
          >
          <div 
            style={{
              position: "absolute",
              left: "-32px",
              top: "10px",
              color: "#fff"
            }}
           >
           ETH
          </div>
          <div 
            style={{
              background: "#ebbb4e",
              height: "40px",
              width: "80px",
              borderRadius: "20px",
            }}
           >
          <a
            style={{
                display: "block",
                position: "absolute",
                background: "#fff",
                height: "36px",
                width: "36px",
                borderRadius: "18px",
                position: "absolute",
                right: "2px",
                top: "2px",
                textDecoration: "none",
              }}
            href="https://staking.lifedefi.co/"
          >
          &nbsp;
          </a>
          </div>
          <div 
            style={{
              position: "absolute",
              right: "-32px",
              top: "10px",
              color: "#fff"
            }}
           >
           BSC
          </div>
        </Box>
        <Container maxWidth="lg">
          <Box display="flex" justifyContent="space-between" p={1}>
            <Box>
              <Box display="flex">
                <Box fontSize={{ maxWidth: "10%" }}>
                  <embed
                    src="logo.svg"
                    alt=""
                    style={{
                      paddingTop: "12px",
                      paddingLeft: matches ? "1rem" : "0rem"
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box py={2}>
              <Button
                onClick={account ? disconnect : connect}
                variant="contained"
                style={{
                  background:
                    "radial-gradient(ellipse at bottom,rgba(85, 180, 200, 1),rgba(85, 180, 200, 0))",
                  color: "#23ead3",

                  boxShadow:
                    "1px 1px 0px #43727a inset, 0px 3px 1px -2px rgb(0 0 0 / 20%),0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                  fontSize: matches ? "1.9rem" : "1rem",
                  // fontSize={{ xs: "1rem", sm: "3rem" }},
                  fontWeight: "700",
                  borderRadius: "50px",
                  padding: "10px 20px",
                  fontFamily: '"Work Sans",sans-serif',
                  margin: ".1rem",
                }}
              >
                {account
                  ? account.slice(0, 6) + "..." + account.slice(-5)
                  : "Connect"}
                {account && (
                  <StyledBadge
                    style={{
                      paddingLeft: "10px",
                    }}
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    variant="dot"
                  />
                )}
              </Button>
            </Box>
          </Box>
        </Container>
        {/* </AppBar> */}

        <Box pt={2}>
          <Container>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box
                  color="#4558D5"
                  fontSize={{ xs: "1rem", sm: "3rem" }}
                  fontWeight="fontWeightBold"
                  fontFamily="'Oswald', sans-serif"
                  style={{
                    paddingLeft: matches ? "1rem" : "0rem",
                  }}
                >
                  LIFE STAKING (BSC)
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} align="right">
                {/* <Box>
                  <Button
                    onClick={() => {
                      ApproveBalance(account);
                    }}
                    variant="contained"
                    style={{
                      color: "#84b7ff",
                      border: "0",
                      background:
                        "radial-gradient(ellipse at bottom,rgba(56, 114, 255, 1),rgba(56, 114, 255, 0))",
                      // "#00EBB4",
                      textTransform: "uppercase",
                      boxShadow:
                        "1px 1px 0px #3872ff inset, 0px 3px 1px -2px rgb(0 0 0 / 20%),0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                      fontSize: matches ? "1.5rem" : "1rem",
                      fontWeight: "700",
                      letterSpacing: "1px",
                      borderRadius: "50px",
                      padding: "10px 18px",
                      marginRight: "0.5rem",

                      fontFamily: '"Work Sans",sans-serif',
                    }}
                  >
                    APPROVED STAKING
                  </Button>
                </Box> */}
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Container>
          <Box
            mt={2}
            style={{
              backgroundColor: "rgba(20, 23, 42, 0.3)",
              borderRadius: "72px 72px 52px 52px",
              padding: "2rem 1rem",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Grid container spacing={3} alignItems="center" align="center">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box
                  style={{
                    padding: "20px 40px",
                    // backgroundColor: "#282B4A",
                    // padding: "20px 40px",
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                    borderRadius: "70px",
                    // boxShadow:
                    //   "rgba(255, 255, 255, 0.12) 0px 2px 4px 0px, rgba(255, 255, 255, 0.32) 0px 2px 16px 0px",
                    // background:"linear-gradient(90deg,rgba(34, 60, 61, 1) 8%,rgba(38, 51, 83, 1) 48%,rgba(35, 37, 46, 1) 100%)",
                    // borderRadius: "70px",
                    // boxShadow:
                    //   "rgba(255, 255, 255, 0.12) 0px 2px 4px 0px, rgba(255, 255, 255, 0.32) 0px 2px 16px 0px",
                  }}
                >
                  <Box>
                    <Grid container spacing={1} align="center">
                      <Grid item xs={12} md={4} sm={12} lg={4}>
                        <Box>
                          <Box
                            color="#ffffff"
                            fontSize={{ sm: "3.6rem", xs: "2rem" }}
                            fontWeight={"700"}
                            fontFamily="'Oswald', sans-serif"
                          >
                            Total Current Balances
                          </Box>
                          <Box
                            color="#00EBB4"
                            fontSize={{ sm: "3.5rem", xs: "2rem" }}
                            fontWeight="fontWeightBold"
                            fontFamily="'Oswald', sans-serif"
                          >
                            {" "}
                            {parseInt(+_totalBalance).toLocaleString()}
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={4} sm={12} lg={4}>
                        <Box>
                          <Box
                            color="#ffffff"
                            fontSize={{ sm: "3.6rem", xs: "2rem" }}
                            fontWeight="fontWeightBold"
                            fontFamily="'Oswald', sans-serif"
                          >
                            Total Current Earnings
                          </Box>
                          <TotalReward />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4} sm={12} lg={4}>
                        <Box>
                          <Box
                            color="#ffffff"
                            fontSize={{ sm: "3.6rem", xs: "2rem" }}
                            fontWeight="fontWeightBold"
                            fontFamily="'Oswald', sans-serif"
                          >
                            Selected Lockups
                          </Box>
                          <Box
                            color="#00EBB4"
                            fontSize={{ sm: "3.5rem", xs: "2rem" }}
                            fontWeight="fontWeightBold"
                            fontFamily="'Oswald', sans-serif"
                          >
                            {" "}
                            {parseInt(selectedLockups).toLocaleString()}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>

              <Box
                style={{
                  padding: "20px 40px",
                  borderRadius: "70px",
                  backgroundColor: "hsla(0,0%,100%,.05)",
                  margin: "0 .8rem",
                  width: "100%",

                  // boxShadow:
                  //   "rgba(255, 255, 255, 0.12) 0px 2px 4px 0px, rgba(255, 255, 255, 0.32) 0px 2px 16px 0px",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  align="center"
                  textAlign="center"
                  alignItems="center"
                  style={{
                    justifyContent: "space-evenly",
                  }}
                >
                  <Grid
                    item
                    align="center"
                    xs={matches ? 12 : 0}
                    md={matches ? 4 : 0}
                    sm={matches ? 2 : 0}
                    style={{
                      width: "7%",
                    }}
                  >
                    <Box>
                      <Box
                        color="white"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="900"
                      >
                        Lockup
                      </Box>
                      <Box
                        color="#00EBB4"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="700"
                      >
                        {" "}
                        1 month
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={matches ? 12 : 0}
                    md={matches ? 4 : 0}
                    sm={matches ? 2 : 0}
                    align="center"
                    style={{
                      width: "5%",
                    }}
                  >
                    <Box>
                      <Box
                        color="white"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="900"
                      >
                        APR
                      </Box>
                      <Box
                        color="#00EBB4"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="700"
                      >
                        {" "}
                        1%
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={matches ? 12 : 0}
                    md={matches ? 4 : 0}
                    sm={matches ? 2 : 0}
                    align="center"
                    style={{
                      width: "7%",
                    }}
                  >
                    <Box>
                      <Box
                        color="white"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="900"
                      >
                        Daily Rate
                      </Box>
                      <Box
                        color="#00EBB4"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="700"
                      >
                        {" "}
                        0.03%
                      </Box>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={matches ? 12 : 0}
                    md={matches ? 4 : 0}
                    sm={matches ? 2 : 0}
                    align="center"
                    style={{
                      width: "10%",
                    }}
                  >
                    <Box>
                      <Box
                        color="white"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="900"
                      >
                        Current Balance
                      </Box>
                      <Box
                        color="#00EBB4"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="700"
                      >
                        {" "}
                        {parseInt(_balanceone).toLocaleString()}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={matches ? 12 : 0}
                    md={matches ? 4 : 0}
                    sm={matches ? 2 : 0}
                    align="center"
                    style={{
                      width: "10%",
                    }}
                  >
                    <Box>
                      <Box
                        color="white"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="900"
                      >
                        Current Earnings
                      </Box>
                      <Reward1 />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={matches ? 12 : 0}
                    md={matches ? 4 : 0}
                    sm={matches ? 2 : 0}
                    align="center"
                    style={{
                      width: "10%",
                    }}
                  >
                    <Box>
                      <Box
                        color="white"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="900"
                      >
                        Remaining Time
                      </Box>
                      <Box
                        color="#00EBB4"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="700"
                      >
                        <TimerCountDown time={_timeone} />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={matches ? 12 : 0}
                    md={matches ? 3 : 0}
                    sm={matches ? 3 : 0}
                  >
                    <Button
                      onClick={() => StakingWith(1)}
                      variant="contained"
                      style={{
                        background:
                          "radial-gradient(ellipse at bottom,#3872ff,rgba(56,114,255,0))",
                        color: "#84B7FF",
                        border: "0",
                        display: "inline-block",
                        textTransform: "uppercase",
                        textDecoration: "none",
                        fontFamily: '"Work Sans",sans-serif',
                        fontSize: matches ? "1.2rem" : ".8rem",
                        fontWeight: "700",
                        letterSpacing: "normal",
                        borderRadius: "50px",
                        padding: "10px 22px",
                        boxShadow:
                          "inset 1px 1px 0 #3872ff,0 3px 1px -2px rgb(0 0 0/20%),0 2px 2px 0 rgb(0 0 0/14%),0 1px 5px 0 rgb(0 0 0/12%)",
                      }}
                    >
                      HARVEST
                      {/* <br />
                        Earning */}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={matches ? 12 : 0}
                    md={matches ? 3 : 0}
                    sm={matches ? 3 : 0}
                  >
                    <Button
                      disabled={_balanceone <= 0 ? true : false}
                      onClick={() => CompleteWithDraw(1)}
                      variant="contained"
                      style={{
                        background:
                          "radial-gradient(ellipse at bottom,#3872ff,rgba(56,114,255,0))",
                        color: "#84b7ff",
                        border: "0",
                        display: "inline-block",
                        textTransform: "uppercase",
                        textDecoration: "none",
                        fontFamily: '"Work Sans",sans-serif',
                        fontSize: matches ? "1.2rem" : ".8rem",
                        fontWeight: "700",
                        letterSpacing: "normal",
                        borderRadius: "50px",
                        padding: "10px 22px",
                        boxShadow:
                          "inset 1px 1px 0 #3872ff,0 3px 1px -2px rgb(0 0 0/20%),0 2px 2px 0 rgb(0 0 0/14%),0 1px 5px 0 rgb(0 0 0/12%)",
                      }}
                    >
                      WITHDRAW
                    </Button>
                  </Grid>

                </Grid>
              </Box>

              <Box
                style={{
                  padding: "20px 40px",

                  backgroundColor: "hsla(0,0%,100%,.05",
                  borderRadius: "70px",
                  margin: "1rem .8rem 0 .8rem",
                  width: "100%",
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "space-between",
                  // marginBottom: "10px",
                  // position: "relative",
                  // fontSize: "14px",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  // align="center"
                  // textAlign="center"
                  alignItems="center"
                  // display="flex"
                  // justify="center"
                  style={{
                    justifyContent: "space-evenly",
                  }}
                >
                  <Grid
                    item
                    align="center"
                    xs={matches ? 12 : 0}
                    md={matches ? 4 : 0}
                    sm={matches ? 2 : 0}
                    style={{
                      width: "7%",
                    }}
                  >
                    <Box pl={0}>
                      <Box
                        color="white"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="900"
                      >
                        Lockup
                      </Box>
                      <Box
                        color="#00EBB4"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="700"
                      >
                        {" "}
                        6 months
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    align="center"
                    xs={matches ? 12 : 0}
                    md={matches ? 4 : 0}
                    sm={matches ? 2 : 0}
                    style={{
                      width: "5%",
                    }}
                  >
                    <Box>
                      <Box
                        color="white"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="900"
                      >
                        APR
                      </Box>
                      <Box
                        color="#00EBB4"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="700"
                      >
                        {" "}
                        2%
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    align="center"
                    xs={matches ? 12 : 0}
                    md={matches ? 4 : 0}
                    sm={matches ? 2 : 0}
                    style={{
                      width: "7%",
                    }}
                  >
                    <Box>
                      <Box
                        color="white"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="900"
                      >
                        Daily Rate
                      </Box>
                      <Box
                        color="#00EBB4"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="700"
                      >
                        {" "}
                        0.011%
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    align="center"
                    xs={matches ? 12 : 0}
                    md={matches ? 4 : 0}
                    sm={matches ? 2 : 0}
                    style={{
                      width: "10%",
                    }}
                  >
                    <Box>
                      <Box
                        color="white"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="900"
                      >
                        Current Balance
                      </Box>
                      <Box
                        color="#00EBB4"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="700"
                      >
                        {" "}
                        {parseInt(_balanceTwo).toLocaleString()}
                      </Box>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    align="center"
                    xs={matches ? 12 : 0}
                    md={matches ? 4 : 0}
                    sm={matches ? 2 : 0}
                    style={{
                      width: "10%",
                    }}
                  >
                    <Box>
                      <Box
                        color="white"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="900"
                      >
                        Current Earnings
                      </Box>

                      <Reward2 />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    align="center"
                    xs={matches ? 12 : 0}
                    md={matches ? 4 : 0}
                    sm={matches ? 2 : 0}
                    style={{
                      width: "10%",
                    }}
                  >
                    <Box>
                      <Box
                        color="white"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="900"
                      >
                        Remaining Time
                      </Box>
                      <Box
                        color="#00EBB4"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="700"
                      >
                        <TimerCountDown time={_timeTwo} />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={matches ? 12 : 0}
                    md={matches ? 3 : 0}
                    sm={matches ? 3 : 0}
                    style={
                      {
                        // marginLeft: matches ? "1.5rem" : "0px",
                      }
                    }
                  >
                    <Button
                      onClick={() => Stakingplane(2)}
                      variant="contained"
                      style={{
                        fontSize: matches ? "1.2rem" : ".8rem",
                        letterSpacing: "1px",
                        borderRadius: "50px",
                        padding: "10px 22px",
                        fontFamily: '"Work Sans", sans-serif',
                        transition:
                          "all 0.35s cubic-bezier(0.19, 1, 0.22, 1) 0s",
                        transform: "scale(1)",
                        textAlign: "center",
                        margin: "0.1rem",
                        background:
                          "radial-gradient(ellipse at bottom,rgba(85, 180, 200, 1),rgba(85, 180, 200, 0))",
                        color: "#23ead3",
                        textTransform: "uppercase",
                        textDecoration: "none",
                        boxShadow:
                          "1px 1px 0px #43727a inset, 0px 3px 1px -2px rgb(0 0 0 / 20%),0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",

                        fontWeight: "700",
                      }}
                    >
                      INVEST
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={matches ? 12 : 0}
                    md={matches ? 3 : 0}
                    sm={matches ? 3 : 0}
                  >
                    <Button
                      onClick={() => StakingWith(2)}
                      variant="contained"
                      style={{
                        background:
                          "radial-gradient(ellipse at bottom,#3872ff,rgba(56,114,255,0))",
                        color: "#84B7FF",
                        border: "0",
                        display: "inline-block",
                        textTransform: "uppercase",
                        textDecoration: "none",
                        fontFamily: '"Work Sans",sans-serif',
                        fontSize: matches ? "1.2rem" : ".8rem",
                        fontWeight: "700",
                        letterSpacing: "normal",
                        borderRadius: "50px",
                        padding: "10px 22px",
                        boxShadow:
                          "inset 1px 1px 0 #3872ff,0 3px 1px -2px rgb(0 0 0/20%),0 2px 2px 0 rgb(0 0 0/14%),0 1px 5px 0 rgb(0 0 0/12%)",
                      }}
                    >
                      HARVEST
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={matches ? 12 : 0}
                    md={matches ? 3 : 0}
                    sm={matches ? 3 : 0}
                    align="center"
                  >
                    <Button
                      disabled={_balanceTwo <= 0 ? true : false}
                      onClick={() => CompleteWithDraw(2)}
                      variant="contained"
                      style={{
                        background:
                          "radial-gradient(ellipse at bottom,#3872ff,rgba(56,114,255,0))",
                        color: "#84b7ff",
                        border: "0",
                        display: "inline-block",
                        textTransform: "uppercase",
                        textDecoration: "none",
                        fontFamily: '"Work Sans",sans-serif',
                        fontSize: matches ? "1.2rem" : ".8rem",
                        fontWeight: "700",
                        letterSpacing: "normal",
                        borderRadius: "50px",
                        padding: "10px 22px",
                        boxShadow:
                          "inset 1px 1px 0 #3872ff,0 3px 1px -2px rgb(0 0 0/20%),0 2px 2px 0 rgb(0 0 0/14%),0 1px 5px 0 rgb(0 0 0/12%)",
                      }}
                    >
                      WITHDRAW
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={matches ? 12 : 0}
                    md={matches ? 3 : 0}
                    sm={matches ? 3 : 0}
                    align="center"
                  >
                    <Button
                      onClick={() => ExtendlockUp(2)}
                      variant="contained"
                      style={{
                        background:
                          "radial-gradient(ellipse at bottom,#d5b36b,rgba(213,179,107,0))",
                        color: "#ffe8b6",
                        letterSpacing: "normal",
                        boxShadow:
                          "inset 1px 1px 0 #a58543,0 3px 1px -2px rgb(0 0 0/20%),0 2px 2px 0 rgb(0 0 0/14%),0 1px 5px 0 rgb(0 0 0/12%)",
                        fontSize: matches ? "1.2rem" : ".8rem",
                        fontWeight: "700",

                        borderRadius: "50px",
                        padding: "10px 20px",

                        fontFamily: '"Work Sans",sans-serif',
                        transition: "all .35s cubic-bezier(.19,1,.22,1)",
                        transform: "scale(1)",
                        textAlign: "center",
                        margin: ".1rem",
                      }}
                    >
                      REINVEST
                    </Button>
                  </Grid>
                </Grid>
              </Box>

              <Box
                style={{
                  padding: "20px 40px",
                  backgroundColor: "hsla(0,0%,100%,.05)",
                  borderRadius: "70px",
                  margin: "1rem .8rem 0 .8rem",
                  width: "100%",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  // align="center"
                  // textAlign="center"
                  alignItems="center"
                  // display="flex"
                  // justify="center"
                  style={{
                    justifyContent: "space-evenly",
                  }}
                >
                  <Grid
                    item
                    align="center"
                    xs={matches ? 12 : 0}
                    md={matches ? 4 : 0}
                    sm={matches ? 2 : 0}
                    style={{
                      width: "7%",
                    }}
                  >
                    <Box pl={0}>
                      <Box
                        color="white"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="900"
                      >
                        Lockup
                      </Box>
                      <Box
                        color="#00EBB4"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="700"
                      >
                        {" "}
                        12 months
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    align="center"
                    xs={matches ? 12 : 0}
                    md={matches ? 4 : 0}
                    sm={matches ? 2 : 0}
                    style={{
                      width: "5%",
                    }}
                  >
                    <Box>
                      <Box
                        color="white"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="900"
                      >
                        APR
                      </Box>
                      <Box
                        color="#00EBB4"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="700"
                      >
                        {" "}
                        4%
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    align="center"
                    xs={matches ? 12 : 0}
                    md={matches ? 4 : 0}
                    sm={matches ? 2 : 0}
                    style={{
                      width: "7%",
                    }}
                  >
                    <Box>
                      <Box
                        color="white"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="700"
                      >
                        Daily Rate
                      </Box>
                      <Box
                        color="#00EBB4"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="700"
                      >
                        {" "}
                        0.011%
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    align="center"
                    xs={matches ? 12 : 0}
                    md={matches ? 4 : 0}
                    sm={matches ? 2 : 0}
                    style={{
                      width: "10%",
                    }}
                  >
                    <Box>
                      <Box
                        color="white"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="900"
                      >
                        Current Balance
                      </Box>
                      <Box
                        color="#00EBB4"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="700"
                      >
                        {" "}
                        {parseInt(_balanceThree).toLocaleString()}
                      </Box>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    align="center"
                    xs={matches ? 12 : 0}
                    md={matches ? 4 : 0}
                    sm={matches ? 2 : 0}
                    style={{
                      width: "10%",
                    }}
                  >
                    <Box>
                      <Box
                        color="white"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="900"
                      >
                        Current Earnings
                      </Box>
                      <Reward3 />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    align="center"
                    xs={matches ? 12 : 0}
                    md={matches ? 4 : 0}
                    sm={matches ? 2 : 0}
                    style={{
                      width: "10%",
                    }}
                  >
                    <Box>
                      <Box
                        color="white"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="900"
                      >
                        Remaining Time
                      </Box>
                      <Box
                        color="#00EBB4"
                        fontSize={{ xs: 10, sm: 20, md: 27, lg: 11 }}
                        fontWeight="700"
                      >
                        <TimerCountDown time={_timeThree} />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={matches ? 12 : 0}
                    md={matches ? 3 : 0}
                    sm={matches ? 3 : 0}
                    style={
                      {
                        // marginLeft: matches ? "1.5rem" : "0px",
                      }
                    }
                  >
                    <Button
                      onClick={() => Stakingplane(3)}
                      variant="contained"
                      style={{
                        fontSize: matches ? "1.2rem" : ".8rem",
                        letterSpacing: "1px",
                        borderRadius: "50px",
                        padding: "10px 22px",
                        fontFamily: '"Work Sans", sans-serif',
                        transition:
                          "all 0.35s cubic-bezier(0.19, 1, 0.22, 1) 0s",
                        transform: "scale(1)",
                        textAlign: "center",
                        margin: "0.1rem",
                        background:
                          "radial-gradient(ellipse at bottom,rgba(85, 180, 200, 1),rgba(85, 180, 200, 0))",
                        color: "#23ead3",
                        textTransform: "uppercase",
                        textDecoration: "none",
                        boxShadow:
                          "1px 1px 0px #43727a inset, 0px 3px 1px -2px rgb(0 0 0 / 20%),0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",

                        fontWeight: "700",
                      }}
                    >
                      INVEST
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={matches ? 12 : 0}
                    md={matches ? 3 : 0}
                    sm={matches ? 3 : 0}
                  >
                    <Button
                      onClick={() => StakingWith(3)}
                      variant="contained"
                      style={{
                        background:
                          "radial-gradient(ellipse at bottom,#3872ff,rgba(56,114,255,0))",
                        color: "#84B7FF",
                        border: "0",
                        display: "inline-block",
                        textTransform: "uppercase",
                        textDecoration: "none",
                        fontFamily: '"Work Sans",sans-serif',
                        fontSize: matches ? "1.2rem" : ".8rem",
                        fontWeight: "700",
                        letterSpacing: "normal",
                        borderRadius: "50px",
                        padding: "10px 22px",
                        boxShadow:
                          "inset 1px 1px 0 #3872ff,0 3px 1px -2px rgb(0 0 0/20%),0 2px 2px 0 rgb(0 0 0/14%),0 1px 5px 0 rgb(0 0 0/12%)",
                      }}
                    >
                      HARVEST
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={matches ? 12 : 0}
                    md={matches ? 3 : 0}
                    sm={matches ? 3 : 0}
                  >
                    <Button
                      disabled={_balanceThree <= 0 ? true : false}
                      onClick={() => CompleteWithDraw(3)}
                      variant="contained"
                      style={{
                        background:
                          "radial-gradient(ellipse at bottom,#3872ff,rgba(56,114,255,0))",
                        color: "#84b7ff",
                        border: "0",
                        display: "inline-block",
                        textTransform: "uppercase",
                        textDecoration: "none",
                        fontFamily: '"Work Sans",sans-serif',
                        fontSize: matches ? "1.2rem" : ".8rem",
                        fontWeight: "700",
                        letterSpacing: "normal",
                        borderRadius: "50px",
                        padding: "10px 22px",
                        boxShadow:
                          "inset 1px 1px 0 #3872ff,0 3px 1px -2px rgb(0 0 0/20%),0 2px 2px 0 rgb(0 0 0/14%),0 1px 5px 0 rgb(0 0 0/12%)",
                      }}
                    >
                      WITHDRAW
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={matches ? 12 : 0}
                    md={matches ? 3 : 0}
                    sm={matches ? 3 : 0}
                  >
                    <Button
                      onClick={() => ExtendlockUp(3)}
                      variant="contained"
                      style={{
                        background:
                          "radial-gradient(ellipse at bottom,#d5b36b,rgba(213,179,107,0))",
                        color: "#ffe8b6",
                        letterSpacing: "normal",
                        boxShadow:
                          "inset 1px 1px 0 #a58543,0 3px 1px -2px rgb(0 0 0/20%),0 2px 2px 0 rgb(0 0 0/14%),0 1px 5px 0 rgb(0 0 0/12%)",
                        fontSize: matches ? "1.2rem" : ".8rem",
                        fontWeight: "700",

                        borderRadius: "50px",
                        padding: "10px 20px",

                        fontFamily: '"Work Sans",sans-serif',
                        transition: "all .35s cubic-bezier(.19,1,.22,1)",
                        transform: "scale(1)",
                        textAlign: "center",
                        margin: ".1rem",
                      }}
                    >
                      REINVEST
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Box>
          <Grid xs={12} lg={12}>
            <Box mt={5} mb={6}>
              <Box
                mb={6}
                color="#4558D5"
                fontSize={{ xs: "1rem", sm: "3rem" }}
                fontWeight="fontWeightBold"
                fontFamily="'Oswald', sans-serif"
                style={{
                  paddingLeft: matches ? "1rem" : "0rem",
                }}
              >
                Progress Bar
              </Box>
              <ProgressBar
                progress={progress}
                staked={staked}
                totalstaked={totalstaked}
                bgcolor="#ebbb4e"
                height={18}
              />
            </Box>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Home;

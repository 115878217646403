import React, { useContext, useEffect, useState } from "react";
import "./App.css";

import Web3 from "web3";
// import Modal from "./modal";

import Home from "./components/home";

import { Box } from "@material-ui/core";
import Accordions from "./components/accordian";
import Footer from "./components/footer";
import NetworkChange from "./networkSwitch";
import { AppContext } from "./context/context";
const web3 = new Web3(
  Web3.givenProvider ? Web3.givenProvider : "https://bsc-dataseed.binance.org/"
);
function App() {
  const [switchNetwork, setswitchNetwork] = useState(false);
  // const [modal, walletModal] = useState(false);
  const { connect, account } = useContext(AppContext);

  // const { account, library, active } = useWeb3React();
  let chain = async () => {
    try {
      if (window.web3) {
        const chainid = await web3.eth.getChainId();
        console.log(chainid, "chainid");
        if (+chainid !== 56) {
          setswitchNetwork(true);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (!account) {
      connect();
    }

    chain();
  }, []);

  return (
    <Box
      style={{
        background: `url(/assets/bacground.svg)`,
        // background: `url(${homeBg})`,
        backgroundSize: "cover",
        backgroundPosition: "50% 1%",
        backgroundRepeat: "no-repeat",
        width: "100%",
      }}
    >
      <NetworkChange open={switchNetwork} setOpen={setswitchNetwork} />
      {/* <Modal setOpen={walletModal} open={modal} /> */}
      <Home />
      <Accordions />
      <Footer />
    </Box>
  );
}

export default App;

import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";

import { parseUnits, formatUnits } from "@ethersproject/units";
import { Box, DialogContent } from "@material-ui/core";
import { toast } from "react-toastify";
import { useStakingContract, useTokenContract } from "../hooks";
import { useWeb3React } from "@web3-react/core";
import { stakingAddress } from "../utils/environment";
import { gasEstimationApprove, gasEstimationPayable } from "../util";
import { AppContext } from "../context/context";

function SimpleDialog(props) {
  const { open, setOpen, index, initOne, initTwo } = props;
  const { account, signer } = useContext(AppContext);
  const StakingContract = useStakingContract(signer);
  const tokenContract = useTokenContract(signer);
  const [amount, setAmount] = React.useState("");
  const [stakingAmount, setStakingAmount] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [maxtoggle, setMaxToggle] = React.useState(false);

  const handleClose = (value) => {
    setOpen(false);
    setAmount("");
    setStakingAmount("");
  };
  const stakeHandler = async () => {
    if (!account) {
      toast.error("Error! Please connect your wallet.");
    } else if (+stakingAmount <= 0) {
      toast.error("Error! Please enter amount.");
    } else {
      setLoading(true);
      try {
        const tokenbalance = await tokenContract.balanceOf(account);
        if (+tokenbalance <= 0) {
          toast.error("you have no tokens.");
        } else {
          const allowance = await tokenContract.allowance(
            account,
            stakingAddress
          );
          let Afn = tokenContract.estimateGas;
          if (+allowance < +stakingAmount) {
            const tx = await tokenContract.approve(
              stakingAddress,
              +stakingAmount,
              {
                gasLimit: await gasEstimationApprove(
                  account,
                  Afn,
                  stakingAddress,
                  tokenbalance
                ),
              }
            );
            await tx.wait();
          }

          let Dfn = StakingContract.estimateGas;
          // const addre = account;

          const tx1 = await StakingContract.deposit(
            // addre,
            index.toString(),
            stakingAmount,
            {
              gasLimit: await gasEstimationPayable(
                account,
                Dfn,
                // addre,
                index.toString(),
                stakingAmount
              ),
            }
          );
          await tx1.wait();
          toast.success("Success! Transaction Confirmed.");
          setLoading(false);
          setAmount("");
          setOpen(false);
        }
        window.location.reload();
      } catch (err) {
        console.log(err, "err====>");
        if (err?.data?.message) {
          toast.error(err?.data?.message);
        } else {
          toast.error(err?.message);
        }
        setLoading(false);
        setAmount("");
      }
    }
  };
  // const setUserAddressHandler = async (e) => {
  //   let addresss = e.target.value.replace(/\s/g, "");
  //   setUserAddress(addresss);
  // };
  const MaxAmount = async () => {
    if (account) {
      setMaxToggle(true);
      const allowance = await tokenContract.balanceOf(account);
      setAmount(parseInt(formatUnits(allowance, 18)));
      setStakingAmount(allowance);
      setMaxToggle(false);
    }
  };

  const onChangeHandler = (e) => {
    setAmount(e.target.value);
    if (e.target.value.length > 0) {
      setStakingAmount(parseUnits(e.target.value.toString(), 18));
    }
  };

  return (
    <Box>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogContent
          style={{
            width: "35rem",
            height: "18rem",
          }}
        >
          <br />

          <Box mt={6} pl={1} pr={1} display="flex" alignItems="center">
            <input
              type="number"
              style={{
                width: "100%",
                height: "3rem",
                background:
                  "radial-gradient(ellipse at bottom,#3872ff,rgba(56,114,255,0))",
                color: "#84B7FF",
                borderRadius: "0px",
                // color: "white",
                border: "none",
                fontSize: "1.7rem",
                textAlign: "center",
                borderRadius: "8px 0 0 8px",
              }}
              value={amount}
              placeholder="0"
              onChange={(e) => onChangeHandler(e)}
            />{" "}
            <Button
              onClick={MaxAmount}
              disabled={maxtoggle}
              // onClick={() => setOpen(true)}
              style={{
                float: "right",
                background:
                  "radial-gradient(ellipse at bottom,rgba(85, 180, 200, 1),rgba(85, 180, 200, 0))",
                boxShadow:
                  "1px 1px 0px #43727a inset, 0px 3px 1px -2px rgb(0 0 0 / 20%),0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                color: "#00EBB4",
                // border: "0",
                display: "inline-block",
                textTransform: "uppercase",
                fontSize: "1.3rem",
                minWidth: "100px",
                letterSpacing: "1px",
                // borderRadius: "50px",
                padding: "10px 20px",
                width: "auto",
                marginTop: ".1rem",

                fontFamily: '"Work Sans",sans-serif',
                transition: "all .35s cubic-bezier(.19,1,.22,1)",
                transform: "scale(1)",
                textAlign: "center",
              }}
            >
              {maxtoggle ? "Load.." : "Max"}
            </Button>
          </Box>
          {/* <Box mt={1} pl={2} pr={2}>
            <input
              type="string"
              style={{
                width: "100%",
                height: "7vh",  
                background:
                  "radial-gradient(ellipse at bottom,#3872ff,rgba(56,114,255,0))",
                color: "#84B7FF",
                borderRadius: "15px",

                border: "none",
              }}
              placeholder="Address"
              value={useraddress}
              onChange={(e) => setUserAddressHandler(e)}
            />
          </Box> */}

          <Box padding={2}>
            <Box>
              {loading && (
                <FacebookCircularProgress
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    left: "46%",
                  }}
                />
              )}
            </Box>
            <Button
              onClick={stakeHandler}
              disabled={loading}
              // onClick={() => setOpen(true)}
              style={{
                background:
                  "radial-gradient(ellipse at bottom,rgba(85, 180, 200, 1),rgba(85, 180, 200, 0))",
                boxShadow:
                  "1px 1px 0px #43727a inset, 0px 3px 1px -2px rgb(0 0 0 / 20%),0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                color: "#00EBB4",
                border: "0",
                display: "inline-block",
                textTransform: "uppercase",
                textDecoration: "none",
                fontSize: "1.7rem",
                fontWeight: "700 !important",
                letterSpacing: "1px",
                borderRadius: "50px",
                padding: "10px 20px",

                fontFamily: '"Work Sans",sans-serif',
                transition: "all .35s cubic-bezier(.19,1,.22,1)",
                transform: "scale(1)",
                textAlign: "center",
                margin: ".1rem",
              }}
              fullWidth
            >
              INVEST
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

SimpleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default function SimpleDialogDemo({
  setOpen,
  open,
  index,
  initOne,
  initTwo,
}) {
  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <SimpleDialog
        open={open}
        // onClose={handleClose}
        setOpen={setOpen}
        index={index}
        initOne={initOne}
        initTwo={initTwo}
      />
    </div>
  );
}

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  top: {
    color: "#4558D5",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));

function FacebookCircularProgress(props) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}

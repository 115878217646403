import React from "react";
import { Contract } from "@ethersproject/contracts";
import tokenAbi from "./utils/tokenAbi.json";
import stakingAbi from "./utils/stakingAbi.json";

import {
  tokenAddress,
  // presaleAddress,
  // airdropAddress,
  stakingAddress,
} from "./utils/environment";
import Web3 from "web3";

const web3 = new Web3(
  Web3.givenProvider ? Web3.givenProvider : "https://bsc-dataseed.binance.org/"
);
function useContract(address, ABI, signer) {
  // const { active, error, library } = useWeb3React();
  return React.useMemo(() => {
    if (address && ABI && signer) {
      // const signer = library.getSigner();
      return new Contract(address, ABI, signer);
    } else {
      return "error";
    }
  }, [address, ABI, signer]);
}
export function useTokenContract(signer) {
  return useContract(tokenAddress, tokenAbi, signer);
}
export function useStakingContract(signer) {
  return useContract(stakingAddress, stakingAbi, signer);
}

export function useStakingContract1() {
  try {
    const StakingContract = new web3.eth.Contract(stakingAbi, stakingAddress);
    return StakingContract;
  } catch (error) {}
}
export function useTokenContract1() {
  try {
    const TokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
    return TokenContract;
  } catch (error) {}
}
